@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add to the top of your index.css file */

/* Force scrollbar to always be present */
html {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* For iOS smooth scrolling */
    height: 100%;
    overscroll-behavior-y: none; /* Prevent bouncing on some browsers */
  }
  
  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior-y: none; /* Prevent bouncing on some browsers */
  }
  
  /* For mobile browsers - prevent font size adjustment */
  @media screen and (max-width: 768px) {
    html {
      -webkit-text-size-adjust: 100%; 
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      text-size-adjust: 100%;
    }
  }
  
  /* Improve tap target sizes on mobile */
  @media (max-width: 640px) {
    button, 
    [role="button"],
    a.button,
    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      min-height: 44px;
      min-width: 44px;
    }
  }
  
  /* Fix for iOS rendering issues */
  input, 
  textarea, 
  button, 
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  /* Smooth scrolling, but not for users with reduced motion preference */
  @media (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
  }
  
  /* Better focus styles for accessibility */
  :focus {
    outline: 2px solid rgb(59, 130, 246);
    outline-offset: 2px;
  }
  
  /* Focus styles that respect reduced motion preferences */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
      scroll-behavior: auto !important;
    }
  }
  
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
  
@keyframes scale-in {
from { transform: translate(-50%, -50%) scale(0.95); }
to { transform: translate(-50%, -50%) scale(1); }
}

@keyframes slide-down {
from { height: 0; }
to { height: var(--radix-collapsible-content-height); }
}

@keyframes slide-up-fade {
from { 
    opacity: 0;
    transform: translateY(2px);
}
to { 
    opacity: 1;
    transform: translateY(0);
}
}

.animate-fade-in {
animation: fade-in 150ms ease-out;
}

.animate-scale-in {
animation: scale-in 150ms ease-out;
}

.animate-slide-down {
animation: slide-down 300ms ease-out;
}

.animate-slide-up-fade {
animation: slide-up-fade 300ms ease-out;
}